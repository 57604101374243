<template>
  <div>
    <b-card title="Filtros">
      <validation-observer>
        <b-form>
          <b-row>
            <b-col md="2">
              <b-form-group label="Data Inicial" label-for="dataInicial">
                <validation-provider #default="{ errors }" name="Data Inicial">
                  <b-input-group>
                    <cleave
                      id="dataInicial"
                      type="text"
                      v-model="dataInicial"
                      placeholder="DD/MM/AAAA"
                      class="form-control cleave-height"
                      :options="options.cleaveDate"
                      :raw="false"
                    />
                    <b-input-group-append>
                      <b-form-datepicker
                        size="sm"
                        button-variant="primary"
                        class="datePickerWithInput"
                        right
                        button-only
                        v-model="dataInicialDatePicker"
                        :hide-header="true"
                        :initial-date="new Date()"
                        :state="errors[0] ? false : null"
                        label-help=""
                        label-calendar="Calendário"
                        label-close-button="Fechar"
                        label-current-month="Mês atual"
                        label-next-decade="Próxima década"
                        label-next-month="Próximo mês"
                        label-next-year="Próximo ano"
                        label-prev-decade="Década anterior"
                        label-prev-month="Mês anterior"
                        label-prev-year="Ano anterior"
                        label-select-month="Selecione o mês"
                        label-select-year="Selecione o ano"
                        label-selected="Selecionado"
                        label-no-date-selected="Nenhuma data selecionada"
                        label-reset-button="Resetar"
                        label-today-button="Hoje"
                        label-today="Hoje"
                        locale="pt-br"
                        :date-format-options="{ year: 'numeric', month: '2-digit', day: 'numeric' }"
                        aria-controls="dataInicial"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="2">
              <b-form-group label="Data Final" label-for="dataFinal">
                <validation-provider #default="{ errors }" name="Data Inicial">
                  <b-input-group>
                    <cleave
                      id="dataFinal"
                      type="text"
                      v-model="dataFinal"
                      placeholder="DD/MM/AAAA"
                      class="form-control cleave-height"
                      :options="options.cleaveDate"
                      :raw="false"
                      :rules="{
                        dataPassada: {
                          value: dataInicial,
                          dataParametro: dataFinal,
                          stringParametro: 'data de inicial',
                        },
                      }"
                    />
                    <b-input-group-append>
                      <b-form-datepicker
                        size="sm"
                        button-variant="primary"
                        class="datePickerWithInput"
                        right
                        button-only
                        v-model="dataFinalDatePicker"
                        :hide-header="true"
                        :initial-date="new Date()"
                        :state="errors[0] ? false : null"
                        label-help=""
                        label-calendar="Calendário"
                        label-close-button="Fechar"
                        label-current-month="Mês atual"
                        label-next-decade="Próxima década"
                        label-next-month="Próximo mês"
                        label-next-year="Próximo ano"
                        label-prev-decade="Década anterior"
                        label-prev-month="Mês anterior"
                        label-prev-year="Ano anterior"
                        label-select-month="Selecione o mês"
                        label-select-year="Selecione o ano"
                        label-selected="Selecionado"
                        label-no-date-selected="Nenhuma data selecionada"
                        label-reset-button="Resetar"
                        label-today-button="Hoje"
                        label-today="Hoje"
                        locale="pt-br"
                        :date-format-options="{ year: 'numeric', month: '2-digit', day: 'numeric' }"
                        aria-controls="dataFinal"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- <b-col md="2">
              <b-form-group label="Versão:" label-for="pesquisarVersao">
                <v-select-pt
                  id="pesquisarVersao"
                  :clearable="false"
                  v-model="pesquisarVersao"
                  :options="listaVersao"
                  label="name"
                  class="tw-w-full tw-max-w-md tw-mb-4 tw-rounded-md tw-shadow-sm tw-focus:outline-none tw-focus:ring-2 tw-focus:ring-blue-500 tw-focus:border-blue-500 tw-sm:text-sm"
                />
              </b-form-group>
            </b-col> -->
            <b-col md="3">
              <b-form-group label="Usuário" label-for="pesquisarUsuario">
                <b-form-input id="pesquisarUsuario" v-model="pesquisarUsuario" />
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group label="Descrições" label-for="pesquisarDescricoes">
                <b-form-input id="pesquisarDescricoes" v-model="pesquisarDescricoes" />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row align-h="end">
            <b-col md="auto">
              <b-button
                :disabled="isBusy"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                style="margin-top: 21px; width: 160px"
                @click="pesquisar()"
              >
                {{ !isBusy ? 'Pesquisar' : '' }}
                <b-spinner small v-if="isBusy" />
              </b-button>
            </b-col>
            <b-col md="auto">
              <template>
                <b-dropdown variant="link" no-caret toggle-class="p-0" left :popper-opts="{ positionFixed: true }">
                  <template #button-content>
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="primary"
                      style="margin-top: 21px; width: 160px"
                    >
                      Exportar
                    </b-button>
                  </template>
                  <b-dropdown-item href="#" @click="exportToPDF()">
                    <feather-icon icon="FileTextIcon" size="16" />
                    <span class="align-middle ml-50" title="Exportar para PDF">PDF</span>
                  </b-dropdown-item>
                  <b-dropdown-item href="#" @click="exportToExcel()">
                    <feather-icon icon="CheckCircleIcon" size="16" />
                    <span class="align-middle ml-50" title="Exportar para excel">Excel</span>
                  </b-dropdown-item>
                </b-dropdown>
              </template>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>

    <b-card v-if="this.itemsTabela.length > 0 || isBusy">
      <template v-if="isBusy">
        <div class="text-center my-2">
          <b-spinner class="align-middle mr-1"></b-spinner>
          <strong>Carregando Tabela...</strong>
        </div>
      </template>

      <div v-if="!isBusy" v-show="this.itemsTabela.length > 0">
        <b-row>
          <b-col md="2">
            <p>
              Total de registros: <b-badge variant="primary"> {{ totalRows }}</b-badge>
            </p>
          </b-col>
        </b-row>

        <b-row>
          <b-col md="12">
            <!-- GRID -->
            <div ref="tabelaGrid" :itemsTabela="itemsTabela">
              <b-table
                style="overflow-x: auto; -webkit-overflow-scrolling: touch"
                outlined
                id="tabelaGrid"
                ref="tabelaGrid"
                striped
                :per-page="perPage"
                :fields="currentFields"
                :items="itemsTabela"
                responsive="sm"
                class="position-relative custom-rounded-table"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :row-details="(row) => row.item.detailsShown"
                @change="handlePageChange"
              >
                <template #cell(role)="row">
                  <p v-if="row.item.role == 'admin'"><b-badge pill variant="primary">Administrador</b-badge></p>
                  <p v-else-if="row.item.role == 'user'">
                    <b-badge pill variant="success">Usuário</b-badge>
                  </p>
                  <p v-else-if="row.item.role == 'master'">
                    <b-badge pill variant="warning">Master</b-badge>
                  </p>
                </template>
              </b-table>
              <b-card-body v-if="this.itemsTabela.length > 0" class="d-flex justify-content-between flex-wrap pt-0">
                <!-- page length -->
                <b-form-group
                  style="align-items: center"
                  label="Por Página"
                  label-cols="6"
                  label-align="left"
                  label-size="sm"
                  label-for="sortBySelect"
                  class="text-nowrap mb-md-0 mr-1"
                >
                  <b-form-select id="perPageSelect" v-model="perPage" size="sm" inline :options="pageOptions" />
                </b-form-group>

                <!-- pagination -->
                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  first-number
                  last-number
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mb-0"
                >
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </b-card-body>
            </div>
          </b-col>
        </b-row>
      </div>
      <div>
        <RelatorioLogAcessoRetrato ref="exportarPDF" :parametro="parametroPDF" id="exportarPDF" />
      </div>
    </b-card>
  </div>
</template>
<script>
  import useJwt from '@/auth/jwt/useJwt'
  import { formatarData, formatarDataHora } from '@/libs/utils'
  import gapData from '@/utils/components/gapData.js'
  import { generateAndDownloadPdf } from '@/utils/geradorPDF/geradorPDF.js'
  import BCardCode from '@core/components/b-card-code/BCardCode.vue'
  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
  import 'cleave.js/dist/addons/cleave-phone.br'
  import { ValidationObserver, ValidationProvider } from 'vee-validate'
  import Cleave from 'vue-cleave-component'
  import VueHtml2pdf from 'vue-html2pdf'
  import Ripple from 'vue-ripple-directive'
  import { VueSelect } from 'vue-select'
  import * as XLSX from 'xlsx'
  import RelatorioLogAcessoRetrato from './Relatorios/RelatorioLogAcessosRetrato.vue'

  export default {
    directives: {
      Ripple,
    },
    components: {
      BCardCode,
      RelatorioLogAcessoRetrato,
      ValidationProvider,
      ValidationObserver,
      cleave: Cleave,
      VueSelect,
      VueHtml2pdf,
      ToastificationContent,
      useJwt,
    },
    data() {
      return {
        userData: this.$jwtDecode(localStorage.getItem('userData')).userData,
        dataInicialDatePicker: '',
        dataFinalDatePicker: '',
        dataInicial: '',
        dataFinal: '',
        pesquisarVersao: '',
        pesquisarUsuario: '',
        pesquisarDescricoes: '',
        listaVersao: [],
        options: {
          cleaveDate: {
            date: true,
            delimiter: '/',
            datePattern: ['d', 'm', 'Y'],
          },
        },
        isBusy: false,
        itemsTabela: [],
        totalRows: 0,
        perPage: 10,
        currentPage: 1,
        pageOptions: [10, 25, 50, 100],
        sortBy: null,
        sortDesc: false,
        sortDirection: 'asc',
        currentFields: [
          { key: 'usuario', label: 'Usuário', sortable: true },
          { key: 'role', label: 'Grupo', sortable: true },
          { key: 'descricao', label: 'Descrição', sortable: true },
          { key: 'ip', label: 'IP' },
          { key: 'data', label: 'Data / Hora', sortable: true },
        ],
        nomeRelatorio: 'Relatório de Log de Acessos',
        parametroPDF: {
          dados: [],
          colunas: [],
        },
        acesso: {
          ConfiguracoesSistemaGerenciamentoLogPesquisar: false,
        },
      }
    },
    mounted() {
      if (this.userData.role == 'master') {
        this.acesso.ConfiguracoesSistemaGerenciamentoLogPesquisar = true
      } else {
        this.acesso.ConfiguracoesSistemaGerenciamentoLogPesquisar =
          this.userData.GrupoAcesso.ConfiguracoesSistemaGerenciamentoLogPesquisar
      }
    },
    methods: {
      handlePageChange(newPage) {
        this.currentPage = newPage
        this.carregarGrid()
      },
      pesquisar() {
        this.currentPage = 1
        if (!gapData(this, this.dataInicial, this.dataFinal)) {
          return
        }
        this.carregarGrid()
      },
      async carregarGrid() {
        this.isBusy = true
        let filtro = {
          role: this.userData.role,
          institutoId: this.userData.institutoSelecionado,
          dataInicial: this.dataInicial ?? null,
          dataFinal: this.dataFinal ?? null,
          usuario: this.pesquisarUsuario ?? null,
          descricao: this.pesquisarDescricoes ?? null,
          sortBy: this.sortDesc == false ? 'desc' : 'asc',
          orderBy: this.sortBy,
          perPage: this.perPage,
          currentPage: this.currentPage,
        }

        useJwt
          .post('utils/GetLogAcesso', filtro)
          .then((response) => {
            if (!response.data.dados.length) {
              this.$message.error('Nenhum registro encontrado!')
              this.itemsTabela = {}
            }

            this.itemsTabela = response.data.dados.map((item) => ({
              ...item,
              usuario: item.name,
              descricao: item.descricao,
              ip: item.ip,
              data: formatarDataHora(item.dateAt),
            }))

            this.totalRows = response.data.total

            this.colunasTabelas = this.currentFields.map((item) => ({
              label: item.label,
              key: item.key,
            }))

            const colunasPDF = [
              { label: 'Usuário', key: 'usuario' },
              { label: 'Grupo', key: 'role' },
              { label: 'Descrição', key: 'descricao' },
              { label: 'IP', key: 'ip' },
              { label: 'Data / Hora', key: 'data' },
            ]
            // Monta variável global de relatório PDF
            this.parametroPDF = {
              dados: this.itemsTabela,
              colunas: colunasPDF.filter((item) => item.key !== 'detalhes'),
              institutoNome: this.userData.Instituto.nome,
            }
          })
          .catch((error) => {
            console.error(error)
            this.isBusy = false
          })
          .finally(() => {
            this.isBusy = false
          })
      },

      exportToPDF() {
        if (!this.itemsTabela.length > 0) this.$message.error('Não há dados para exportar!')

        if (this.$refs.tabelaGrid) {
          let htmlContent = this.$refs.exportarPDF.$children[0].$refs.html2Pdfportrait.$el.innerHTML
          let relatorioPaisagem = false
          let nomeInstituto = this.userData.Instituto.nome
          let periodoFiltro = `Período: ${this.dataInicial} a ${this.dataFinal}`
          let nomeRelatorio = this.nomeRelatorio
          let informacoesInstituto = `
                      ${this.userData.Instituto.logradouro},
                      ${this.userData.Instituto.numero},
                      ${this.userData.Instituto.bairro},
                      ${this.userData.Instituto.cep} - ${this.userData.Instituto.cidade} - ${this.userData.Instituto.uf}
                      E-mail: ${this.userData.Instituto.emailResponsavel} - Fone:${this.userData.Instituto.telefone}
                    `
          generateAndDownloadPdf(
            this,
            htmlContent,
            nomeInstituto,
            periodoFiltro,
            nomeRelatorio,
            informacoesInstituto,
            relatorioPaisagem,
          )
        } else {
          console.error('Elemento da tabela não encontrado')
        }
      },
      exportToExcel() {
        if (!this.itemsTabela.length) {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Alerta!',
                text: 'Não há dados para exportar!',
                icon: 'BellIcon',
                variant: 'danger',
              },
            },
            {
              position: 'top-right',
              timeout: 8000,
            },
          )
          return
        }

        // Usando os dados e colunas para criar a planilha
        const ws = XLSX.utils.json_to_sheet(
          this.itemsTabela.map((item) => ({
            Usuario: item.usuario,
            Role: item.role,
            Descricao: item.descricao,
            IP: item.ip,
            'Data / Hora': item.data,
          })),
        )

        const wb = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(wb, ws, 'Relatorio')

        // Salvando o arquivo
        XLSX.writeFile(wb, 'Relatorio.xlsx')
      },
    },
    watch: {
      sortDesc(newVal) {
        this.sortDesc = newVal
        this.carregarGrid()
      },
      dataInicialDatePicker(newVal) {
        this.dataInicial = formatarData(this.dataInicialDatePicker)
      },
      dataFinalDatePicker(newVal) {
        this.dataFinal = formatarData(this.dataFinalDatePicker)
      },
      currentPage: function () {
        this.carregarGrid()
      },
      perPage: function () {
        this.carregarGrid()
      },
    },
  }
</script>
